<template>
  <div ref="container">
    <b-card :title="$t('apiAuthorization.header')">
      <vs-tabs>
        <vs-tab :label="$t('apiAuthorization.key.tabTitle')">
          <ResultsAuthorizationKey />
        </vs-tab>
        <vs-tab :label="$t('apiAuthorization.domainWhitelist.tabTitle')">
          <ResultsAuthorizationWhitelistTable />
        </vs-tab>
      </vs-tabs>
    </b-card>
  </div>
</template>

<script>
import ResultsAuthorizationWhitelistTable from '@/views/components/results/ResultsAuthorizationWhitelistTable.vue'
import ResultsAuthorizationKey from '@/views/components/results/ResultsAuthorizationKey.vue'

export default {
  components: { ResultsAuthorizationKey, ResultsAuthorizationWhitelistTable },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {},
}
</script>
