<template>
  <vs-row>
    <vs-col vs-w="12" class="mb-2">
      {{ $t('apiAuthorization.domainWhitelist.header') }}
    </vs-col>

    <vs-row class="mb-1 pt-1 pb-1 font-weight-bold">
      <vs-col vs-w="11" class="pl-1">
        {{ $t('apiAuthorization.domainWhitelist.domain') }}
      </vs-col>
      <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
        {{ $t('apiAuthorization.domainWhitelist.action') }}
      </vs-col>
    </vs-row>
    <vs-row v-for="(item,k) in getDomainsList" :key="k" class="pt-1 pb-1 rounded" :class="{'bg-light': k % 2}">
      <vs-col vs-w="11" class="pl-1">
        {{ item }}
      </vs-col>
      <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
        <a class="d-b-inline" href="#" @click.prevent="confirmDelete(k)">
          <vs-tooltip :text="$t('apiAuthorization.domainWhitelist.deleteTooltip')">
            <trash-2-icon class="text-danger" />
          </vs-tooltip>
        </a>
      </vs-col>
    </vs-row>

    <vs-col vs-w="12" class="mb-2 mt-4">
      {{ $t('apiAuthorization.domainWhitelist.desc') }}
    </vs-col>
    <vs-row class="addTable p-1 ">
      <validation-provider
        ref="newDomainValidator" v-slot="validationContext" rules="required|domain"
        name="newDomain" class="w-100"
      >
        <vs-col vs-w="12" class="mb-1">
          {{ $t('apiAuthorization.domainWhitelist.newDomainLabel') }}
        </vs-col>
        <vs-col>
          <vs-col vs-w="10" vs-sm="12" class="pr-md-1 pr-0 pb-1">
            <vs-input
              v-model="newDomain"
              :danger="isDanger(validationContext)"
              :danger-text="validationContext.errors[0]"
              size="large"
              class="w-100"
            />
          </vs-col>
          <vs-col vs-w="2" vs-sm="12">
            <vs-button class="w-100" @click="addDomain()">
              {{ $t('apiAuthorization.domainWhitelist.add') }}
            </vs-button>
          </vs-col>
        </vs-col>
      </validation-provider>
    </vs-row>
  </vs-row>
</template>

<script>

import store from '@/store'
import { Trash2Icon } from 'vue-feather-icons'

export default {
  name: 'ResultsAuthorizationWhitelistTable',
  components: { Trash2Icon },
  props: {},
  data() {
    return {
      newDomain: '',
    }
  },
  computed: {
    getDomainsList() {
      return store.state.authorizationWhitelist.domainsList
    },
    dataLoading() {
      return store.state.authorizationWhitelist.dataLoading
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      store.dispatch('authorizationWhitelist/getData')
      this.domainsList = store.state.domainsList
    },
    removeProtocol(domain) {
      return domain.replace(/^(\w+:)?\/\//, '')
    },
    addDomain() {
      this.$refs.newDomainValidator.validate().then(result => {
        if (result.valid) {
          store.commit('authorizationWhitelist/addDomain', this.removeProtocol(this.newDomain))
          store.dispatch('authorizationWhitelist/saveDomains')
          this.newDomain = ''
          this.$refs.newDomainValidator.reset()
        }
      })
    },
    confirmDelete(index) {
      store.commit('authorizationWhitelist/removeDomain', index)
      store.dispatch('authorizationWhitelist/saveDomains')
    },
    isDanger(validation) {
      return validation.validated ? !validation.valid : false
    },
  },
}

</script>

<style>
/* validation error font size */
.vs-input--text-validation-span {
  font-size: 1rem !important;
}

.addTable {
  background-color: #f5f5f5;
  border-radius: 8px;
}
</style>
