<template>
  <vs-row>
    <vs-row class="mb-2">{{ $t('apiAuthorization.key.header') }}</vs-row>
    <vs-row class="mb-2 mb-sm-1">
      <vs-col vs-lg="10" vs-sm="12" vs-xs="12" class="mb-1">
        <vs-input class="w-100" :value="getUrl" :label="$t('apiAuthorization.key.url')" disabled />
      </vs-col>
      <vs-col
        vs-lg="2" vs-sm="6" vs-xs="12"
        class="pl-lg-1 mb-1" vs-type="flex" vs-justify="right"
        vs-align="end"
      >
        <b-button variant="outline-primary" class="w-100" @click="copyUrl">
          <copy-icon size="12" />
          {{ $t('apiAuthorization.key.copyUrl') }}
        </b-button>
      </vs-col>
    </vs-row>
    <vs-row class="mb-2">
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="mb-1 mb-lg-0 pr-lg-1">
        <vs-input class="w-100" :value="getKey" :label="$t('apiAuthorization.key.key')" disabled />
      </vs-col>
      <vs-col
        vs-lg="2" vs-sm="6" vs-xs="12"
        class="pr-sm-1 pr-lg-0 mb-1 mb-sm-0" vs-type="flex" vs-justify="right"
        vs-align="end"
      >
        <b-button variant="outline-primary" class="w-100" @click="resetKey">
          <trash2-icon size="12" />
          {{ $t('apiAuthorization.key.resetKey') }}
        </b-button>
      </vs-col>
      <vs-col
        vs-lg="2" vs-sm="6" vs-xs="12"
        class="pl-sm-1" vs-type="flex" vs-justify="right"
        vs-align="end"
      >
        <b-button variant="outline-primary" class="w-100" @click="copyKey">
          <copy-icon size="12" />
          {{ $t('apiAuthorization.key.copyKey') }}
        </b-button>
      </vs-col>
    </vs-row>
    <vs-row class="mb-2 mb-sm-1">
      <vs-col vs-lg="10" vs-sm="12" vs-xs="12" class="mb-1">
        <vs-input class="w-100" :value="getCustomerSymbol" :label="$t('apiAuthorization.key.customerSymbol')" disabled />
      </vs-col>
      <vs-col
        vs-lg="2" vs-sm="6" vs-xs="12"
        class="pl-lg-1 mb-1" vs-type="flex" vs-justify="right"
        vs-align="end"
      >
        <b-button variant="outline-primary" class="w-100" @click="copyCustomerSymbol">
          <copy-icon size="12" />
          {{ $t('apiAuthorization.key.copyUrl') }}
        </b-button>
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>

import store from '@/store'
import { Trash2Icon, CopyIcon } from 'vue-feather-icons'
import CustomerStore from "@/store/user/customer";
import Notifications from '@/store/notifications/notifications'

export default {
  name: 'ResultsAuthorizationKey',
  components: { Trash2Icon, CopyIcon },
  props: {},
  data() {
    return {
      newDomain: '',
    }
  },
  computed: {
    getUrl() {
      return store.state.authorizationKey.url
    },
    getKey() {
      return store.state.authorizationKey.key
    },
    getCustomerSymbol() {
      return CustomerStore.state.customerSymbol
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }
      document.body.removeChild(textArea)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)

    },
    getData() {
      store.dispatch('authorizationKey/getData')
    },
    copyUrl() {
      this.copyTextToClipboard(this.getUrl)
      Notifications.commit('addPopupInfo', {
        name:this.$t('notifications.copiedUrl.name'),
        message: this.$t('notifications.copiedUrl.message'),
      }, { root: true })
    },
    copyKey() {
      this.copyTextToClipboard(this.getKey)
      Notifications.commit('addPopupInfo', {
        name: this.$t('notifications.copiedKey.name'),
        message: this.$t('notifications.copiedKey.message'),
      }, { root: true })
    },
    copyCustomerSymbol() {
      this.copyTextToClipboard(this.getCustomerSymbol)
      Notifications.commit('addPopupInfo', {
        name: this.$t('notifications.copiedCustomerSymbol.name'),
        message: this.$t('notifications.copiedCustomerSymbol.message'),
      }, { root: true })
    },
    resetKey() {
      store.dispatch('authorizationKey/resetKey')
    },
  },
}
</script>

<style scoped>
.urlRow > div {
  max-width: calc(100% - 170px);
  flex: 0 0 calc(100% - 170px);
}

.urlRow > div:last-of-type {
  max-width: 170px;
  flex: 0 0 170px;
}

.keyRow > div {
  max-width: calc(100% - 400px);
  flex: 0 0 calc(100% - 400px);
}

.keyRow > div:last-of-type {
  max-width: 400px;
  flex: 0 0 400px;
}
</style>
